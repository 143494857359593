<template>
  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeGjV80IxTdnd2s8KDOf-oJG_eK_L5HVlR1MS0LQzcuscjcQg/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
</template>

<script>

export default {
  components: {
  }
}
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
